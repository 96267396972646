<template>
  <div class="action-wrapper">
    <h2
      class="h2"
      :class="selectedText ? 'visible' : 'invisible'"
    >
      {{ selectedText }} items selected
    </h2>
    <div class="buttons">
      <the-button
        :disabled="!selectedAll && !selectedItems.length > 0"
        outer-class="w-full"
        primary
        @click="exportProductsToCSV"
      >
        <template #default> Export selected to .csv </template>
        <template #icon>
          <ic-export :size="18" />
        </template>
      </the-button>
      <the-button
        outer-class="w-full"
        primary
        :disabled="!selectedItems.length > 0"
        @click="copyToClipboard"
      >
        <template #default> Copy IDs to clipboard </template>
        <template #icon>
          <ic-content-copy :size="18" />
        </template>
      </the-button>
    </div>
  </div>
</template>

<script>
import IcMenu from 'vue-material-design-icons/Menu'
import IcTableOfContents from 'vue-material-design-icons/TableOfContents'
import IcExport from 'vue-material-design-icons/ExportVariant'
import IcPencil from 'vue-material-design-icons/Pencil'
import IcContentCopy from 'vue-material-design-icons/ContentCopy'
import IcPause from 'vue-material-design-icons/Pause'
import { mapActions, mapGetters } from 'vuex'
import { exportToCsv } from '@/utils/util'
import Toast from '@/components/shared/Toast'

export default {
  name: 'OverviewTableActions',
  components: { IcMenu, IcExport, IcPencil, IcContentCopy, IcPause, IcTableOfContents },
  props: {
    selectedItems: {
      type: Array,
    },
    selectedAll: {
      type: Boolean,
    },
    totalProducts: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      table_display_btn: 'productOverview/tableDisplayBtn',
      headers: 'productOverview/headers',
      store: 'store/selectedStore',
    }),
    selectedText() {
      return this.selectedAll
        ? this.totalProducts
        : !this.selectedAll && this.selectedItems.length
          ? this.selectedItems.length
          : null
    },
  },
  methods: {
    ...mapActions({
      setDisplayBtn: 'productOverview/setDisplayTableBtn',
      exportAllToCsv: 'productOverview/exportAllProductsToCSV',
    }),
    async exportProductsToCSV() {
      if (this.selectedAll) {
        try {
          await this.exportAllToCsv()
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Successfully exported to .csv',
              type: 'success',
            },
          })
        } catch (e) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Unexpected error exporting to .csv',
              type: 'error',
            },
          })
        }
      } else {
        const csvProducts = this.selectedItems.map(item => {
          return this.headers.map(header => {
            return item[header.accessibility]
          })
        })
        const csvHeaders = this.headers.map(header => header.header)
        exportToCsv(`${this.store.name}.csv`, [csvHeaders, ...csvProducts])
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Successfully exported to .csv',
            type: 'success',
          },
        })
      }
    },
    async copyToClipboard() {
      if (!this.selectedAll) {
        const ids = this.selectedItems.map(item => item.product_id).join('\n')
        try {
          await navigator.clipboard.writeText(ids)
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Copied to clipboard',
              type: 'success',
            },
          })
        } catch (e) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Unexpected error copying to clipboard',
              type: 'error',
            },
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.action-wrapper {
  @apply flex  flex-col flex-wrap justify-between items-center;
  .buttons {
    @apply mt-3 flex flex-1 w-full flex-col gap-2;
  }
}

@screen md {
  .action-wrapper {
    @apply flex flex-row flex-wrap justify-between items-center;

    .buttons {
      @apply mt-0 flex flex-row w-auto justify-end flex-auto gap-2;
    }
  }
}
</style>
