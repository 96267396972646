<template>
  <div class="filter-wrapper">
    <div class="flex flex-wrap justify-between">
      <div class="upper-filter">
        <h2>Filters</h2>
      </div>
      <div class="chip-filter">
        <strong v-if="pureFiltersArray.length">{{ totalProducts }} products match your filters</strong>
        <div class="flex gap-3">
          <the-button
            @click.stop="openDatepicker = true"
            chip
            :override-inner-style="innerClassOverride"
            :override-outer-style="outerClassOverride"
            class="relative"
          >
            <label class="datepicker font-dm-mono">{{ formatPrimaryRangeDate }}</label>
            <template #icon>
              <label class="cursor-pointer">
                <ic-calendar-month :size="18" />
              </label>
            </template>
            <template #transition-container>
              <overview-date-range
                v-if="openDatepicker"
                :open-datepicker="openDatepicker"
                @close="openDatepicker = !openDatepicker"
              ></overview-date-range>
            </template>
          </the-button>
          <base-picker
            input-id="campaign"
            input-name="campaign"
            :items="getCampaigns(storeId)"
            :value="getSelectedCampaign"
            :item-label="item => item.name"
            :item-value="item => item.name"
            :error="null"
            :hide-feedback="true"
            :remove-radius="true"
            :unselect="'All campaigns'"
            placeholder="Bidbrain AI campaign"
            @input="setFilterByCampaign"
          >
            <template #customIcon>
              <ic-tag :size="18" />
            </template>
          </base-picker>
        </div>
      </div>
    </div>
    <filters
      :metrics="metrics"
      :filters="filters"
      @addFilter="addFilter"
      @deleteFilter="removeFilter"
      @editFilter="addFilter"
      @clearAll="clearAll"
    ></filters>
  </div>
</template>

<script>
import IcPlus from 'vue-material-design-icons/Plus'
import FiltersContainer from '@/components/product/overview/ProductFiltersContainer.vue'
import BasePicker from '@/components/input/base/BasePicker'
import IcCalendarMonth from 'vue-material-design-icons/CalendarMonth'
import IcTag from 'vue-material-design-icons/Tag'
import IcClose from 'vue-material-design-icons/Close'
import DatePicker from '@/components/date/DatePicker'
import OverviewDateRange from '@/components/product/overview/overviewTableFilter/OverviewDateRange'
import { mapActions, mapGetters } from 'vuex'
import Filters from '@/components/table/Filters.vue'

const dayjs = require('dayjs')

export default {
  name: 'OverviewTableFilters',
  components: {
    Filters,
    OverviewDateRange,
    DatePicker,
    BasePicker,
    FiltersContainer,
    IcPlus,
    IcCalendarMonth,
    IcTag,
    IcClose,
  },
  props: {
    totalProducts: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
      openDatepicker: false,
      metrics: [
        { name: 'Availability', value: 'availability', type: 'is_any_of', prefix: null },
        { name: 'Avg. CPC', value: 'cpc', type: 'number', prefix: 'currency' },
        { name: 'Clicks', value: 'clicks', type: 'number', prefix: null },
        { name: 'Conv. value', value: 'conv_value', type: 'number', prefix: 'currency' },
        { name: 'Conv. value / Click', value: 'cvpc', type: 'number', prefix: 'currency' },
        { name: 'Conversions', value: 'conversions', type: 'number', prefix: null },
        { name: 'Cost', value: 'cost', type: 'number', prefix: 'currency' },
        { name: 'Custom label 1', value: 'custom_label_1', type: 'text', prefix: null },
        { name: 'Custom label 2', value: 'custom_label_2', type: 'text', prefix: null },
        { name: 'Custom label 3', value: 'custom_label_3', type: 'text', prefix: null },
        { name: 'Custom label 4', value: 'custom_label_4', type: 'text', prefix: null },
        { name: 'Gender', value: 'gender', type: 'text', prefix: null },
        { name: 'Impressions', value: 'impressions', type: 'number', prefix: null },
        { name: 'MPN', value: 'mpn', type: 'text', prefix: null },
        { name: 'Price', value: 'product_price', type: 'number', prefix: 'currency' },
        { name: 'Product type', value: 'product_type', type: 'text', prefix: null },
        { name: 'Profit', value: 'profit', type: 'number', prefix: 'currency' },
        { name: 'ROAS', value: 'roas', type: 'number', prefix: 'percentage' },
        { name: 'Sale price', value: 'product_sale_price', type: 'number', prefix: 'currency' },
        { name: 'Title', value: 'product_title', type: 'text', prefix: null },
      ],
    }
  },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
      filters: 'productOverview/filters',
      primaryRangeDate: 'productOverview/primaryDateRange',
      getCampaigns: 'campaign/getCampaignsByStoreId',
      getSelectedCampaign: 'productOverview/selectedCampaign',
      currencyById: 'currency/getCurrencyById',
      store: 'store/selectedStore',
    }),
    formatPrimaryRangeDate() {
      if (this.primaryRangeDate) {
        const from = dayjs(this.primaryRangeDate.from).format('YYYY/MM/DD')
        const to = dayjs(this.primaryRangeDate.to).format('YYYY/MM/DD')
        return `${from}-${to}`
      }
      return ''
    },
    pureFiltersArray() {
      return this.filters.filter(filter => filter.id !== 0)
    },
    outerClassOverride() {
      return { 'border-radius': 0, padding: 0 }
    },
    innerClassOverride() {
      return { paddingTop: '0.5rem', paddingBottom: '0.5rem' }
    },
    currency() {
      return this.currencyById(this.store.currency_id)?.symbol || 'N/A'
    },
  },
  methods: {
    ...mapActions({
      addFilters: 'productOverview/addFilters',
      deleteFilter: 'productOverview/deleteFilter',
      clearAllFilters: 'productOverview/clearAllFilters',
      getProducts: 'productOverview/getProducts',
      setCampaign: 'productOverview/setCampaign',
    }),
    async removeFilter(filter) {
      await this.deleteFilter(filter)
      if (filter.id !== 0) {
        await this.$router.push({ query: null })
        await this.getProducts({ query: { ...this.$route.query, page: 1 }, total: false })
      }
    },
    async addFilter(filter) {
      await this.addFilters(filter)
      if (filter.id !== 0) {
        await this.$router.push({ query: null })
        await this.getProducts({ query: { ...this.$route.query, page: 1 }, total: false })
      }
    },
    async editFilter(filter) {
      await this.addFilters(filter)
      await this.$router.push({ query: null })
      await this.getProducts({ query: { ...this.$route.query, page: 1 }, total: false })
    },
    async clearAll() {
      await this.clearAllFilters()
      await this.$router.push({ query: null })
      await this.getProducts({ query: { ...this.$route.query, page: 1 }, total: false })
    },
    async setFilterByCampaign(name) {
      await this.setCampaign(name)
      await this.$router.push({ query: null })
      await this.getProducts({ query: { ...this.$route.query, page: 1 }, total: false })
    },
  },
}
</script>

<style scoped lang="scss">
.datepicker {
  @apply font-medium text-sm cursor-pointer;
}

.filter-wrapper {
  @apply flex flex-col gap-8;

  .upper-filter {
    @apply flex  flex-wrap gap-3 justify-between items-center mr-2;
    > h2 {
      @apply w-full;
    }

    > b {
      @apply mb-3;
    }
  }

  .chip-filter {
    @apply flex flex-wrap gap-3 items-center;
  }

  .filters-container {
    @apply absolute mt-1 z-10;
    width: 90.5%;
  }

  .buttons-wrapper {
    @apply grid grid-cols-1 gap-3;
  }

  @screen md {
    .buttons-wrapper {
      @apply flex flex-wrap gap-3;
    }

    .filters-container {
      @apply absolute mt-2 z-10;
      width: fit-content;
    }

    .upper-filter {
      @apply flex flex-wrap gap-3 flex-1 justify-between items-center mr-2;
    }
  }

  @screen lg {
    .filters-container {
      @apply absolute mt-2 z-10;
      width: 750px;
    }
  }
}
</style>
