<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-groups
            title="Products"
            :size="32"
          />
        </template>
        Products
      </page-title>
    </page-section>
    <div v-if="!proStore">
      <page-section>
        <upgrade-subscription>
          <template #content>
            This report is only available for paying subscription. You can test this feature during 30 days for FREE.
          </template>
        </upgrade-subscription>
      </page-section>
    </div>
    <div v-else-if="!account.sales_led && !mainSteps">
      <page-section>
        <base-alert type="information">
          <template #title> Information </template>
          <template #content> Complete the onboarding to get started with Bidbrain </template>
          <template #action>
            <the-button
              @click="$router.push({ name: 'dashboard' })"
              information
            >
              Complete&nbsp;onboarding
              <template #icon>
                <ic-chevron-right :size="16" />
              </template>
            </the-button>
          </template>
        </base-alert>
      </page-section>
    </div>
    <div v-else>
      <page-section
        :no-bottom-padding="overallLoad"
        bottom-border
      >
        <div v-if="!overallLoad">
          <loader-ellipsis class="text-bb-neon-purple transform" />
          Loading products
        </div>
        <hero v-else>
          <template #image>
            <img
              src="/content/images/illustrations/stats.svg"
              alt="Let Bidbrain do the heavy lifting"
              class="sofa-guy"
            />
          </template>
          <template #title> Discover your products </template>
          <template #description>
            Our products overview will provide a general view of all your products at once. With standard filters like
            date and range as well as custom filters like target ROAS, you can be in full control of your product
            status. Several features are currently being worked on and will be available in beta stage soon.
          </template>
          <template #links>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://academy.bidbrain.com/faq"
            >
              <template #text> FAQ </template>
            </bid-link>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://academy.bidbrain.com/how-to-create-a-bidbrain-shopping-campaign"
            >
              <template #text> Our Guides </template>
            </bid-link>
            <bid-link
              :router-link="false"
              primary
              target="_blank"
              href-link="https://blog.bidbrain.com"
            >
              <template #text> Blog & Articles </template>
            </bid-link>
          </template>
        </hero>
      </page-section>
      <div v-if="overallLoad">
        <page-section
          bottom-border
          full-width
        >
          <overview-table-filters :total-products="products.pagination.total_products"></overview-table-filters>
        </page-section>
        <div ref="container"></div>
        <page-section
          v-if="products.products.length"
          bottom-border
          full-width
        >
          <overview-table-actions
            :total-products="products.pagination.total_products"
            :selected-all="selectAll"
            :selected-items="selected"
          />
        </page-section>
        <data-table
          v-if="products.products.length"
          @selectAll="selectAllItems"
          @sort="sortHeader"
          @selected="selected = $event"
          :items="products.products"
          :headers="headers"
          :footer-items="products.aggregations"
          :column-defs="columnDefs"
          :loading="tableLoading"
          :has-check-box="true"
        >
          <template #product-title="{ value, item, selectProduct, isChecked }">
            <div class="flex items-center gap-3">
              <img
                id="product-img"
                style="height: 72px"
                v-lazy="item.images"
                width="72"
                height="72"
                :alt="item.brand"
              />
              <div
                class="flex flex-1 items-center justify-between cursor-pointer"
                @click.stop="rowSelect(item)"
              >
                <span class="underline text-bb-neon-purple">{{ value }}</span>
                <ic-chevron-right :size="16" />
              </div>
            </div>
          </template>
          <template #tfoot-product-title>
            <div class="flex items-center">
              <div class="flex gap-3">
                <span>Total products in filter: {{ products.pagination.total_products }}</span>
              </div>
            </div>
          </template>
          <template #tfoot-product-title-length>
            <div class="flex items-center">
              <div class="flex gap-3">
                <span>Average title length: {{ products.aggregations.average_length_product_title.value }}</span>
              </div>
            </div>
          </template>
          <template #product-title-length="{ value, item, i }">
            <div class="flex items-center">
              <div class="flex gap-3">
                <span class="my-auto">{{ item.product_title_length }}</span>
                <new-tooltip :theme="qualityTooltip(item.product_title_quality)">
                  <QualityBadge
                    class="my-auto"
                    :success="item.product_title_quality == 'Good'"
                    :warn="item.product_title_quality == 'Ok'"
                    :error="item.product_title_quality == 'Poor'"
                  >
                    <template>
                      <span>{{
                        item.product_title_quality === 'Good'
                          ? 'Great'
                          : item.product_title_quality === 'Ok'
                            ? 'Good'
                            : item.product_title_quality === 'Poor'
                              ? 'Poor'
                              : null
                      }}</span>
                    </template>
                  </QualityBadge>
                  <template #content> Your product title should contain between 70 - 150 characters </template>
                </new-tooltip>
              </div>
            </div>
          </template>
          <template #item-id="{ value, item, i }">
            <div class="flex items-center gap-3">
              <new-tooltip>
                <template #content>
                  <template>
                    <div>
                      <strong>Brand</strong>: <span>{{ item.brand }}</span>
                    </div>
                    <div>
                      <strong>Category</strong>: <span>{{ item.google_category }}</span>
                    </div>
                    <div>
                      <strong>Product type</strong>:
                      <span>{{ item.product_type | specialCharRegex }}</span>
                    </div>
                    <div>
                      <strong>Price</strong>:
                      <span>{{ dynamicCurrency }} {{ item.product_price }}</span>
                    </div>
                    <div>
                      <strong>Sale price</strong>:
                      <span>{{ dynamicCurrency }} {{ item.product_sale_price }}</span>
                    </div>
                    <div>
                      <strong>GTIN/MPN</strong>: <span>{{ item.gtin }}</span>
                    </div>
                    <div v-if="item.url">
                      <strong>Product url</strong>:
                      <bid-link
                        :href-link="item.url"
                        inherit
                        target="_blank"
                        :router-link="false"
                      >
                        <template #text>
                          <p class="underline m-0 truncate-custom">{{ item.url }}</p>
                        </template>
                      </bid-link>
                    </div>
                  </template>
                </template>
              </new-tooltip>
              <span>{{ value | prepend('#') }}</span>
            </div>
          </template>
          <template #availability="{ value, item, i }">
            <new-tooltip
              class="w-4"
              :theme="
                value === 'in stock'
                  ? 'grey'
                  : value === 'backorder'
                    ? 'warn'
                    : value === 'preorder'
                      ? 'warn'
                      : !value
                        ? 'infogrey'
                        : 'error'
              "
            >
              <template #content>
                <span class="capitalize">{{ value ? value : 'Not synchronized' }}</span>
              </template>
            </new-tooltip>
          </template>
          <template #performance-label="{ value, item }">
            <div class="flex">
              <QualityBadge
                class="my-auto"
                :success="item.performance_label === 'Performer'"
                :warn="item.performance_label === 'Low volume performer'"
                :error="item.performance_label === 'Underperformer'"
              >
                <template>
                  <span>{{ value ? value : 'Not synchronized' }}</span>
                </template>
              </QualityBadge>
            </div>
          </template>
          <template #header-bench-price>
            <div class="flex gap-1">
              <span class="text-bb-mid-grey">Bench. /</span>
              <span>Your price</span>
            </div>
          </template>
          <template #bench-price="{ item }">
            <div class="flex flex-col gap-3">
              <span
                v-if="item.pr_avg_price"
                class="text-bb-mid-grey"
                >{{ item.pr_avg_price | concat(' ' + dynamicCurrency) }}</span
              >
              <span
                v-else
                class="text-bb-mid-grey"
                >-</span
              >
              <div class="flex gap-2">
                <span>{{ item.product_price | concat(' ' + dynamicCurrency) }}</span>
                <div v-if="item.pr_avg_price && item.product_price">
                  <span
                    v-if="calculatePercentage(item.pr_avg_price, item.product_price) > 0"
                    class="text-bb-dull-red font-semibold"
                  >
                    {{
                      calculatePercentage(item.pr_avg_price, item.product_price)
                        | toFixed(2)
                        | prepend('+')
                        | concat('%')
                    }}</span
                  >
                  <span
                    v-else
                    class="text-bb-dull-green font-semibold"
                  >
                    {{ calculatePercentage(item.pr_avg_price, item.product_price) | toFixed(2) | concat('%') }}</span
                  >
                </div>
              </div>
            </div>
          </template>
          <template #impressions="{ value, item }">
            <span>{{ value }}</span>
          </template>
          <template #tfoot-impressions="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) }}</span>
          </template>
          <template #cpc="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
            </div>
          </template>
          <template #tfoot-cpc="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
          </template>
          <template #clicks="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value }}</span>
            </div>
          </template>
          <template #tfoot-clicks="{ items, header }">
            <span>{{ items[header.footerAccessibility].value }}</span>
          </template>
          <template #conv-value="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
            </div>
          </template>
          <template #tfoot-conv-value="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
          </template>
          <template #cost="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
            </div>
          </template>
          <template #tfoot-cost="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
          </template>
          <template #roas="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) | concat('%') }}</span>
            </div>
          </template>
          <template #tfoot-roas="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | concat('%') }}</span>
          </template>
          <template #conversions="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) }}</span>
            </div>
          </template>
          <template #tfoot-conversions="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) }}</span>
          </template>
          <template #header-profit="{ header }">
            <new-tooltip direction="left">
              <span>{{ header.header }}</span>
              <template #content>
                <span>Profit = Conversion Value - Cost</span>
              </template>
            </new-tooltip>
          </template>
          <template #profit="{ value, item }">
            <div class="flex flex-col">
              <span>{{ value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
            </div>
          </template>
          <template #tfoot-profit="{ items, header }">
            <span>{{ items[header.footerAccessibility].value | toFixed(2) | concat(' ' + dynamicCurrency) }}</span>
          </template>
          <template #pagination>
            <div class="flex flex-wrap justify-between items-center gap-5">
              <page-size
                :items="['50', '100', '250']"
                :default-limit="defaultLimit"
                :default-page="defaultPage"
              />
              <pagination
                @onChange="scrollToTop"
                v-if="products && products.pagination"
                :max="products.pagination.total_products"
                show-first-and-last
                :page="$route.query.page ? +$route.query.page : defaultPage"
                :limit="$route.query.limit ? +$route.query.limit : defaultLimit"
              />
            </div>
          </template>
        </data-table>
        <shape
          v-else
          slot-full-width
        >
          <div
            v-if="tableLoading"
            class="flex justify-center items-center"
          >
            <loader-ellipsis class="text-bb-neon-purple transform" />
            Loading products
          </div>
          <div v-else>
            <base-alert
              v-if="filterSet"
              type="warn"
            >
              <template #title> No products found </template>
              <template #content> There are no products matching your filters </template>
            </base-alert>
            <base-alert
              v-else
              type="information"
            >
              <template #title> Fetching product data </template>
              <template #content>
                Check back soon, we are fetching and generating insights from your product data. This can take up to 48
                hours.
              </template>
            </base-alert>
          </div>
        </shape>
      </div>
    </div>
  </page>
</template>

<script>
import IcGroups from 'vue-material-design-icons/Shape'
import IcInfo from 'vue-material-design-icons/AlertCircle'
import DataTable from '@/components/table/DataTable'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from '@/components/input/base/BaseCheckBox'
import BidLink from '@/components/base/BidLink'
import OverviewTableActions from '@/components/product/overview/OverviewTableActions'
import OverviewTableFilters from '@/components/product/overview/overviewTableFilter/OverviewTableFilters'
import Hero from '@/components/shared/Hero'
import PageSize from '@/components/table/PageSize'
import Pagination from '@/components/table/Pagination'
import Loader from '@/components/loader/Loader'
import LoaderEllipsis from '@/components/loader/EllipsisLoader'
import UpgradeSubscription from '@/components/alert/UpgradeSubscription'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import BaseAlert from '@/components/alert/BaseAlert'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import QualityBadge from '@/components/shared/QualityBadge'
import NewTooltip from '@/components/alert/NewTooltip.vue'

export default {
  name: 'Overview',
  components: {
    BaseAlert,
    PageSection,
    Page,
    UpgradeSubscription,
    LoaderEllipsis,
    Loader,
    Pagination,
    PageSize,
    Hero,
    OverviewTableFilters,
    OverviewTableActions,
    BidLink,
    BaseCheckBox,
    DataTable,
    IcGroups,
    IcInfo,
    IcChevronRight,
    QualityBadge,
    NewTooltip,
  },
  filters: {
    toFixed(value, fixed) {
      if (!value) return value
      return value.toFixed(fixed)
    },
    concat(value, string) {
      if (!value) return value
      return value.toString().concat(string)
    },
    prepend(value, string) {
      if (!value) return value
      return string + '' + value
    },
    specialCharRegex(value) {
      return value.replaceAll(/[["\]\\]+/g, '')
    },
  },
  data() {
    return {
      selected: [],
      selectAll: false,
    }
  },
  watch: {
    async '$route.query'(val) {
      if (this.overallLoad) {
        if (val.hasOwnProperty('page') || val.hasOwnProperty('limit')) {
          await this.getProducts({ query: this.$route.query, total: true })
        }
      }
    },
    async storeId() {
      await this.setOverallLoad(false)
      await this.clearOnChangeStore()
      await this.loadData()
    },
  },
  methods: {
    ...mapActions({
      getProducts: 'productOverview/getProducts',
      getFilters: 'productOverview/getFilters',
      getRangeDates: 'productOverview/getRangeDate',
      sort: 'productOverview/sort',
      loadCampaigns: 'campaign/loadCampaignsByStoreIdIfNeeded',
      loadCurrencies: 'currency/loadCurrenciesIfNeeded',
      setOverallLoad: 'productOverview/setOverallLoad',
      clearOnChangeStore: 'productOverview/clearOnChangeStore',
      setColumnsVisibility: 'productOverview/setColumnsVisibility',
    }),
    selectAllItems(val) {
      switch (val.state) {
        case null:
        case true:
          this.selectAll = true
          break
        case false:
          this.selectAll = false
          break
      }
    },
    calculatePercentage(avgPrice, price) {
      return ((price - avgPrice) / avgPrice) * 100
    },
    qualityTooltip(value) {
      return value === 'Good' ? 'success' : value === 'Ok' ? 'warn' : value === 'Poor' ? 'error' : 'info'
    },
    scrollToTop(pager) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: pager.current_page,
        },
      })
      this.$nextTick(() => {
        seamless.scrollIntoView(this.$refs.container, {
          behavior: 'smooth',
        })
      })
    },
    async sortHeader(val) {
      await this.sort(val)
      await this.getProducts({ query: this.$route.query, total: true })
    },
    rowSelect(product) {
      this.$router.push({
        name: 'products:overview:details',
        params: {
          id: product.product_id,
        },
      })
    },
    columnVisible() {
      const enabledStorePerformance = this.storeSettings('ENABLE_PERFORMANCE_LABELS')
      if (enabledStorePerformance) {
        this.headers.forEach(header => {
          if (header.accessibility === 'performance_label') {
            this.setColumnsVisibility({ ...header, visible: true })
          }
        })
      } else {
        this.headers.forEach(header => {
          if (header.accessibility === 'performance_label') {
            this.setColumnsVisibility({ ...header, visible: false })
          }
        })
      }
    },
    async loadData() {
      if (this.proStore && this.mainSteps) {
        this.columnVisible()
        await this.getRangeDates()
        await this.getFilters()
        await this.loadCurrencies()
        await this.loadCampaigns(this.storeId)
        await this.getProducts({ query: this.$route.query, total: false })
      }
    },
  },
  computed: {
    ...mapGetters({
      overallLoad: 'productOverview/overAllLoad',
      tableLoading: 'productOverview/tableLoading',
      filters: 'productOverview/filters',
      primaryDateRange: 'productOverview/primaryDateRange',
      products: 'productOverview/products',
      headers: 'productOverview/headers',
      defaultPage: 'productOverview/defaultPage',
      defaultLimit: 'productOverview/defaultLimit',
      storeId: 'store/selectedStoreId',
      store: 'store/selectedStore',
      currency: 'currency/getCurrencyBySelectedStore',
      account: 'account/getAccount',
      steps: 'onBoardingForm/steps',
      mainSteps: 'onBoardingForm/mainStepCompleted',
      columnDefs: 'productOverview/columnDefs',
      storeSettings: 'store/storeSettings',
    }),
    proStore() {
      return this.store.subscription_slug === 'pro' || this.store.subscription_slug === 'pro_plus'
    },
    dynamicCurrency() {
      if ([39, 42, 132].includes(this.currency.id)) {
        return this.currency.symbol || 'N/A'
      } else {
        return this.currency.code || 'N/A'
      }
    },
    filterSet() {
      return !!this.filters && this.filters.filter(item => !!item.value).length
    },
  },
  mounted() {
    this.loadData()
  },
  destroyed() {
    this.setOverallLoad(false)
  },
}
</script>
